































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component({})
export default class UpdateEmail extends Vue {
public inProcess = false;
public changeEmailObj: any = {};
public invalidEmail: any = null;
public emailMatched: any = null;
public duplicateEmail: any = null;

public async changeEmail() {
try {
        let formValid = await this.$validator.validateAll("changeEmail");
        if (!formValid) return;
        this.clearErrorMsg();

        this.inProcess = true;

        let response = await Axios.post(
        BASE_API_URL + "authentication/verifyAndUpdateEmail",
        this.changeEmailObj);

        if (response.data.data == "InvalidEmail") {
        this.invalidEmail = "Your current email is incorrect.";
        }
        if (response.data.data == "EmailMatched") {
        this.emailMatched = "Current email and new email should not be same.";
        }

        if (response.data.data == "DuplicateEmail") {
        this.duplicateEmail = "new email already exists.";
        }

        if (response.data.data == "Success") {
        this.$snotify.info(
        "Email has been successfully changed. You logged in your account after email verification"
        );
        localStorage.setItem("logout-event", "user_logged_out" + Math.random());
        this.$store.dispatch("clearSession");
        this.$router.push({ name: "Login" });
        }

        this.inProcess = false;
        } catch (error) {
        this.inProcess = false;
        }
        }
public hasType() {
return this.$userType=='Wemlo Processor' ? true : false;
}

    public clearErrorMsg(){
        this.emailMatched = null;
        this.duplicateEmail = null;
        this.invalidEmail = null;
    }    
}
