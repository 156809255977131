



import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import PreRequisiteHeader from "@/views/preRequisiteHeader/PreRequisiteHeader.vue";
import TermsAndCondition from "@/components/broker/pre-requisites/contract/Contract.vue";

import { HollowDotsSpinner } from "epic-spinners";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";
import UpdateEmail from "@/views/UpdateEmail.vue"
import stores from "../../../store/store";
import EDisclosureAgreement from '@/views/EDisclosureAgreement.vue';
import _ from "lodash";

const store: any = stores;
let  w = window as any;
const Vuebar = w.Vuebar as any;
Vue.use(Vuebar);;
Component.registerHooks(["beforeRouteEnter"]);

@Component({ components: { HollowDotsSpinner, UpdateEmail, EDisclosureAgreement } })

 export default class BorrowerDashboardComponent extends Vue {
  public changePasswordObj: any = {};
  public invalidPassword: any = null;
  public inProcess = false;
  public passwordMatched : any = null;
  public borrowerData: any = null;
  public invalidNewPassword = false;
  canChangeEDisclosures = false;

  get loanTxnId() {
    return this.$store.state.sessionObject.userInfo.loanTxnId;
  }

  get eDisclosureAgreement() {
    return _.get(this.$store.state.sessionObject, 'pendingActions.eDisclosureAgreement', null);
  }

  openEDisclosure() {
    this.$modal.show('eDisclosureModal');
  }

  eDisclosureAccepted() {
    this.$modal.hide('eDisclosureModal');
    this.$forceUpdate();
  }

  public async getBorrowerInfo(){
    try{
      let response = await Axios.post(
        BASE_API_URL + "borrower/getBorrowerAccountInfo",
        {
          loanTxnId: this.loanTxnId,
          userId: this.$userId
        });

        if (response.status == 200) {
          this.borrowerData = response.data;
        }
      
    }catch(error){
      console.log(error);
    }
  }

  async checkIfBorrowerCanChangeEDisclosures() {
    try {
      const { data: { canChangeEDisclosureAgreement } } = await Axios.post(
        `${BASE_API_URL}borrower/canBorrowerChangeEDisclosureAgreement`,
        { loanTxnId: this.loanTxnId, borrowerId: this.$userId }
      );

      this.canChangeEDisclosures = canChangeEDisclosureAgreement;
    } catch(error) {
      console.error(error);
    }
  }


  public async changePassword() {
    try {
      let formValid = await this.$validator.validateAll();
      if (!formValid) return;
      this.inProcess = true;
      this.changePasswordObj.userId = this.$userId;
      let response = await Axios.post(
        BASE_API_URL + "authentication/verifyAndChangePassoword",
        this.changePasswordObj);
      if (response.data.data == "Success") {
       this.$snotify.success("Password has been successfully changed.");
       this.$router.push("/broker/dashboard");
      } else if (response.data.data == "InvalidPassword") {
        this.invalidPassword = "Your current password is incorrect.";
      } else if (response.data.data == "passwordMatched") {
        this.passwordMatched =
          "New password cannot be the same as last five passwords.";
      } else if (response.data.data == "invalidNewPassword") {
        this.invalidNewPassword = true;
      }
      this.inProcess = false;
    } catch (error) {
      
    }
  }

async mounted() {
  await this.getBorrowerInfo();
  await this.checkIfBorrowerCanChangeEDisclosures();
}
 }
 